@import "variables.scss";

html, body{
  width: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  color: white;
  line-height: 1.4;
}

*{
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1,h2, h3,h4{
  margin: 0;
  padding: 0;
}
h3{
  font-weight: 300;
  text-transform: uppercase;
  font-size: 0.7em;

}

a {
  text-decoration: none;
  cursor: pointer;
  color: $pop;
}
ul{
  padding: 0px;
  margin: 0px;
}
li {
  list-style: none;
}
li,
p{
  line-height: 1.6;
}
.hint{
  font-size: 0.8em;
  color: $text-med;
}
.btn{
  display: inline-block;
  padding: 10px 30px;
  border: 1px solid $pop;
  border-radius: 0px;
  color: $pop;
  font-size: 0.9em;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  &:hover{
    background-color: $pop;
    color: white;
  }
  &:disabled{
    background-color: #333;
    text-decoration: line-through;
    opacity: 0.5;
  }
  &:active{
    background: url(assets/images/art-pattern.gif) center center repeat;
    color: $black;
  }
}
pre.icon{
  display: inline-block;
  font-size: 2px;;

}
input[type="text"]{
  background-color: transparent;
  color: $white;
  border: 1px dotted $pop;
  padding: 5px;
  &:focus{
    outline: none;
    box-shadow: none;
    border-style: solid;
  }
}