@import "./variables";

	.rules{
		// border: 1px solid #ccc;
		padding: 5px 0;
		font-size: 0.9em;
		a.open{
			display: block;
			color: $pop-alt;
			span{
				display: inline-block;
				padding-left: 5px;
				&:before{
					content: '▼';
				}
			}
		}
		p,
		ul{
			display: none;
		}
		p{
			margin: 0;
			margin-bottom: 0px;
		}
		ul{
			margin-bottom: 20px;
		}
		&.open{
			max-height: 50vh;
			display: flex;
			flex-direction: column;
			z-index: 2;
			border-top: 1px solid $white;
			.rules-wrap{
				overflow: auto;
			}
			p, ul{
				display: block;
			}
			a.open{
				margin-bottom: 15px;
				color: $pop;
				span{
					&:before{
						content: '▲';
					}
				}
			}
		}
	}