@import "variables";
.create-responses{
	.form-group{
		position: relative;
		.char-count{
			position: absolute;
			bottom: 10px;
			right: 10px;
			font-size: 0.8rem;
			color: $text-med;
		}
	}
	.samesies{
		margin-top: 50px;
		h2{
			margin-bottom: 20px;
		}
	}
	textarea {
		width: 100%;
		height: 83px;
		background-color: transparent;
		color: $text-color; 
		bordeR: none;
		border-bottom: 1px solid $text-color;
		border-radius: 0;
		padding: 15px 0px;
		outline: none;
		font-size: 1.4em;
		line-height: 1.4;
	}
	.enter{
		padding: 5px 0;
		color: $pop;
		font-style: italic;
	}
	ul.responses{
		margin-top: 10px;
		text-align: left;
	}
	.stats{
		margin-top: 15px;
		// text-align: right;
	}
	.how-many{
		// text-align: right;
		padding-bottom: 10px;
		font-size: 0.8rem;
		color: $text-med;
	}
	.saved{
		padding: 30px 0px;
		color: $pop;
	}
}