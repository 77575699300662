
.App {
  padding: 15px;
  max-width: 500px;
  margin: 0 auto;
  nav {
    display: flex;
    justify-content: space-between;
    a{
      display: block;
      height: 50px;
    }
    pre{
      font-size: 1.3px;
      display: block;
    }
  }
  .main-content{
    width: 100%;
    max-width: 700px;
    padding-top: 30px;
    h1{
      margin-bottom: 10px;
    }
  }
}