@import "variables";
.welcome{
	> footer{
		position: fixed;
		bottom: 0px;
		width: 100%;
		left: 0px;
		padding: 10px 15px;
		background-color: $bg;
		h3 {
			position: absolute;
			right: 15px;
			top: 18px;
		}
	}
	.game-wrap{
		text-align: center;
		.controls{
			margin-bottom: 10px;
			button{
				background-color: transparent;
				color: $pop;
				font-size: 1.5rem;
				border: 1px solid $pop;
				margin: 10px;
				padding: 5px 20px;
				&:active{
					background: url(assets/images/art-pattern.gif) center center repeat;
					color: $black;
				}
			}
		}
	}
	&.round-2 {
		.game-wrap{
			.round-two{
				.option-wrap{
					padding: 100px 0;
					h2{
						font-size: 1.8rem;
						margin-bottom: 20px;
					}
					.responses-left{

					}
					p.hint{
						position: absolute;
						bottom: 50px;
						left: 0;
						width: 100%;
					}
				}
			}
		}
	}
}