.user-page{
	.icon {
		text-decoration: none;
		display: inline-block;
		padding: 0px 5px;
	}
	ul{
		padding: 0px;
		margin: 0px 0px 30px;
	}
	.metamask{
		margin-bottom: 30px;
	}
	li.persp{
		list-style: none;
		padding-bottom: 10px;
		.label-wrap{
			display: none;
		}
		&.open{
			.label-wrap{
				display: block;
			}
		}
		input[type="text"]{
			width: 100%;
			margin-bottom: 10px;
		}
	}

}